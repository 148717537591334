import React from "react";

const Footer = (props) => {
  return (
    <footer>
      <div className="container">
        <p className="footer-text">made with ❤ in Egham</p>
      </div>
    </footer>
  );
};

export default Footer;
