// extracted by mini-css-extract-plugin
export var navbar = "Layout-module--navbar--2j9Pm";
export var navbarLight = "Layout-module--navbar-light--5u31f";
export var bgCompsoc = "Layout-module--bg-compsoc--xk7-b";
export var navbarNav = "Layout-module--navbar-nav--Fv9Hh";
export var navLink = "Layout-module--nav-link--1wLpq";
export var hero = "Layout-module--hero--2Bcy2";
export var btnGroup = "Layout-module--btn-group--3dwNi";
export var btn = "Layout-module--btn--rTQ1M";
export var fullscreen = "Layout-module--fullscreen--28Zea";
export var full = "Layout-module--full--21pVD";
export var half = "Layout-module--half--1fA_P";
export var dark = "Layout-module--dark--utu57";
export var orange = "Layout-module--orange--35vRO";
export var sectionTag = "Layout-module--section-tag--mpzIl";
export var link = "Layout-module--link--1geLd";
export var disabled = "Layout-module--disabled--3ukU6";
export var media = "Layout-module--media--1T5yU";
export var news = "Layout-module--news--3Uftw";
export var description = "Layout-module--description--2PG6h";
export var links = "Layout-module--links--Zb1rm";
export var events = "Layout-module--events--2G4ju";
export var event = "Layout-module--event--2skfs";
export var item = "Layout-module--item--hOz3C";
export var box = "Layout-module--box--MSs_k";
export var itemBody = "Layout-module--item-body--3ayoi";
export var icon = "Layout-module--icon--2JuC6";
export var name = "Layout-module--name--5auEB";
export var WhatWeDo = "Layout-module--WhatWeDo--3pi6D";
export var features = "Layout-module--features--3lVKU";
export var team = "Layout-module--team--23T4J";
export var card = "Layout-module--card--MKxx4";
export var cardContent = "Layout-module--card-content--2pqeR";
export var cardPicture = "Layout-module--card-picture--3-5T4";
export var picture = "Layout-module--picture--3q7xS";
export var footerText = "Layout-module--footer-text--2LITU";
export var layout = "Layout-module--layout--36KWw";